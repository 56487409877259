import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Introduction from "../../components/introduction"
import HeroAlt from "../../components/hero-alt"
import Spacer from "../../components/spacer"
import TitleAndText from "../../components/title-and-text"
import ImageAndText from "../../components/image-and-text"
import BulletList from "../../components/bullet-list"
import Bullet from "../../components/bullet"
import CenteredSlider from "../../components/centered-slider"
import CenteredSlide from "../../components/centered-slide"
import WorkGrid from "../../components/work-grid"
import ImageGridItemService from "../../components/image-grid-item-service"
import ContactForm from "../../components/contact-form"
import ImageGridItem from "../../components/image-grid-item"
import Testimonials from "../../components/testimonials"


class WebDevelopmentPage extends Component {
  render() {
    const data = this.props.data

    return (
      <Layout background={ true } >
        <SEO title="Web Development Bournemouth" />
        <HeroAlt 
          image="/surfdurt.jpg"
          title="Website Development"
          subtitle="Old Salt has nearly 15 years of experience building beautiful, functional, optimised websites."
        />

        <Spacer space="4" />    

        <div className="row shrink-width align-center">

          <div className="column small-12 large-4">
            <h2 className="gm-bottom--1" style={{ lineHeight: 1 }}>Helping you to tell your story </h2>  
          </div>
          <div className="column small-12 large-8">
            <div className="row align-center">
              <div className="column small-12 large-10">
                <p>Old Salt has nearly 15 years of experience building beautiful, functional, optimised websites. We work with a huge range of platforms including WordPress/WooCommerce, Shopify, Craft CMS, Kirby CMS and BigCommerce as well as providing bespoke website development.</p>
                <p>We pride ourselves on building websites that:</p>
                <BulletList>
                  <Bullet key="1" text="Look fantastic" />
                  <Bullet key="2" text="Are easy to use" />
                  <Bullet key="3" text="Are fully optimised for all screen sizes" />
                  <Bullet key="4" text="Are Ultra-fast" />
                  <Bullet key="5" text="Are Accessible" />
                  <Bullet key="5" text="Are Maintainable" />
                </BulletList>
                <p>We’ll work with you to define your goals, establish your brand identity and get you to market as soon as possible.</p>
                <p>We believe that a website launch is the beginning of the journey. At Old Salt, we use an iterative approach to website development. This means after launch, we look at how customers are using your website, identify areas that are causing friction and monitor these changes to continually improve your website and its effectiveness.</p>
              </div>
              
            </div>
          </div>
        </div>

        <div className="gm-top--2 gm-bottom--2 contact-form--pullout">
          <div className="clearfix"></div>
          <div className="row align-center gm-top--4 gm-bottom--4">
            <div className="column small-12 medium-10 large-8">
              <h3>Get in touch</h3>
              <p>We're driven by bringing great ideas to life and working with great people. Get in touch to find out more.</p>
              <Spacer space="2" />
              <ContactForm pageName="website-development-bournemouth" />
            </div>
          </div>
          <div className="clearfix"></div>
        </div>

        <Spacer space="3" />    

        <div className="row shrink-width align-center">
          <div className="column small-12 large-4">
            <h3 className="gm-bottom--1" style={{ lineHeight: 1 }}>“How much is a website?”</h3>  
          </div>
          <div className="column small-12 large-8">
            <div className="row align-center">
              <div className="column small-12 large-10">
                <p>This is a question that we get asked a lot and it's a fair one but it’s also a difficult one to answer. There are numerous factors that could influence the cost. For example, if you have a large amount of content, if there are integrations with a particular CRM, if you need to be able to edit the content yourself etc. etc. However, we can work to your budget and provide incredible value. We will never try and sell you things that you really don’t need and we’ll work with you to find a solution that works.</p>   
              </div>
            </div>
          </div>
        </div>

       <Spacer space="3" />    

       <div className="row align-middle shrink-width gm-top--1 gm-bottom--1">
          <div className="column small-12 medium-6 medium-text-left text-center">
            <h3 className="no-margin-bottom button-title">Our Work</h3>
            <p className="gm-bottom--1">Below is a selection of some of the work that we've produced here at Old Salt.</p>
          </div>
          <div className="column small-12 medium-6 text-center medium-text-right">
            <Link to="/work" className="button no-margin-bottom">View work</Link>
          </div>
        </div>
        <WorkGrid>
          <ImageGridItem width={5} image={ '/dbm.jpg' } subtitle="WordPress" title="DBM Group" link="https://www.dbmgroup.com/" showLink="true" />
          <ImageGridItem width={7} image={ '/feria.jpg' } subtitle="WordPress" title="Feria Urbanism" link="https://feria-urbanism.com/" showLink="true" />
          <ImageGridItem width={7} image={ '/first-wealth.jpg' } subtitle="WordPress" title="First Wealth" link="https://www.firstwealth.co.uk/" showLink="true" />
          <ImageGridItem width={5} image={ '/connock.jpg' } subtitle="Shopify" title="Connock London" link="https://www.connocklondon.co.uk/" showLink="true" />
        </WorkGrid>          

        <Spacer space="3" />    

        <div className="row shrink-width align-center">
          <div className="column small-12 large-4">
            <h2 className="gm-bottom--1" style={{ lineHeight: 1 }}>Why use a freelancer over an agency?</h2>  
          </div>
          <div className="column small-12 large-8">
            <div className="row align-center">
              <div className="column small-12 large-10">
                <p>Using a freelancer over an agency provides a number of benefits. I manage the timeline, speak with you directly and create the website. All of this means that there is no chain of command, there is no chance of requests and intentions getting lost in translation. You'll work with me from start to finish and we'll work closely to bring the project to completion.</p>
              </div>
            </div>
          </div>
        </div>

        <Spacer space="4" />    

        <TitleAndText title="Platforms we work with" text="We work in all areas of digital from email marketing to full-scale e-commerce solutions. Below are some of the platforms that we specialise in." />
        <Spacer space="4" />    
        <ImageAndText image={'/shopify-experts.jpg'} title="Shopify" text={<><p>Shopify is a complete e-commerce solution for your business. Old Salt are official Shopify partners. With over 6 years experience in e-commerce solutions, we will help you get to market in the right way.</p></>} link="/services/shopify-development-bournemouth" />
        <Spacer space="4" />    
        <ImageAndText flip={true} image={'/wordpress-experts.jpg'} title="Wordpress & WooCommerce" text={<><p>Currently supporting around 30% of all of the websites on the internet today, no doubt you will have come across WordPress. We have several years of experience working with WordPress. WordPress is powerful and robust content management system to use as the platform for your next project.</p></>} link="/services/wordpress-development-bournemouth" />
        <Spacer space="4" />    
        <ImageAndText image={'/craft-experts.jpg'} title="Craft CMS" text={<><p>Craft CMS is a flexible, user-friendly CMS for creating custom digital experiences. We have found the Craft CMS is the ultimate content management system when it comes to create a bespoke solution for your next project.</p></>} link="/services/craft-cms-bournemouth" />
        <Spacer space="4" />    
        <ImageAndText flip={true} image={'/kirby-experts.jpg'} title="Kirby CMS" text={<><p>Kirby CMS is lightweight and hugely flexible content management system that provides excellent security. Kirby CMS allows you to have things your way without the clutter normally found in more well-known CMS systems.</p></>} link="/kirby-cms-a-super-secure-alternative" linktext="Read more" />      

        <Spacer space="4" />  

        <Testimonials />
        
        <Spacer space="4" />   
        <CenteredSlider>
          {data.allWordpressPost.edges.map(({ node }) => (
              <CenteredSlide key={node.id} title={node.title} image={ node.featured_media.localFile.childImageSharp.original.src } link={node.path} />
          ))}
        </CenteredSlider> 
      </Layout>
    )
  }
}

export default WebDevelopmentPage

export const pageQuery = graphql`
  query {
    allWordpressPost(sort: {fields: [date], order: DESC}) {
      edges {
        node {
          id
          slug
          path
          title
          featured_media {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }        
        }
      }
    }
  }
`